
import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';
import { globals } from '../utils/themes';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
    root:{
    display:'flex',
    justifyContent:'space-between', alignItems: 'center',
     padding:'10px 20px', position: 'fixed', top:0, left:0,  width:'100%', 
     zIndex:1,
    backgroundColor:theme.palette.background.default,
        '& .pageTitle':{
        fontSize:'1rem',
        color:theme.palette.text.primary,
        fontWeight: 500,
        margin:0, cursor: 'default',
    },
    '& .left':{
        display:'flex', justifyContent:'center', alignItems: 'center', gap:13,
    },
    '& .backbtn':{
        borderRadius:globals.borderRadius,
        // backgroundColor:theme.palette.primary.tintMain,
        fontSize:'14px',
        cursor:'pointer', height:24,
        // padding:'2px 10px',
        '& path':{
            fill:theme.palette.text.primary
        }
    }
}
}));

function Head({backTo='/',currentPageTitle='Crypto Wallet', showArrow=true, children}) {
    const nav = useNavigate();
    const location = useLocation();
    const classes = useStyles();

    const navigateTo = (path='/',data=null)=>{
        nav(path, {state:data})
    }

    const from = useMemo(() => {
        const fromState = location.state?.prevRoute;
        // This is used to preserve the previous page when navigating back to it.
        // The page ID is a combination of the 'from' state and the current path,
        // to ensure that the 'from' state is only preserved for the same page.
        const pageId = `${fromState}||${backTo}>>${window.location.pathname}`
        const fromStorage = localStorage.getItem(pageId);

        if (fromState) {
            localStorage.setItem(pageId, fromState);
            return fromState;
        } else if (fromStorage) {
            return fromStorage;
        } else {
            return null;
        }
        
    }, [location.state]);
   

    return (
        <div className={classes.root}>
        <div className='left' role='button' tabIndex={-1} onClick={()=>navigateTo(from || backTo)}>
            <div style={!showArrow ? {visibility:'hidden'} : {}} className="backbtn" >
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M3 12C3 11.4477 3.44772 11 4 11L20 11C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13L4 13C3.44772 13 3 12.5523 3 12Z" fill="#151B1F"/>
                    <path d="M5.02786 12C5.12116 12.1548 5.31675 12.4329 5.53062 12.6749C5.95637 13.1568 6.5431 13.7072 7.1556 14.2389C7.76313 14.7664 8.3736 15.2564 8.83397 15.6156C9.06363 15.7948 9.45995 16.0942 9.59308 16.1948C10.0377 16.5223 10.1327 17.1483 9.8052 17.5929C9.47769 18.0376 8.85169 18.1326 8.40699 17.8051L8.40328 17.8023C8.2589 17.6933 7.84028 17.3771 7.60352 17.1923C7.1264 16.82 6.48686 16.307 5.84439 15.7492C5.20689 15.1957 4.54362 14.5784 4.03187 13.9992C3.777 13.7108 3.53856 13.4082 3.35842 13.1094C3.19484 12.838 3.00002 12.4432 3 12C3.00002 11.5569 3.19484 11.162 3.35842 10.8906C3.53856 10.5918 3.777 10.2892 4.03186 10.0008C4.54362 9.4216 5.20689 8.80434 5.84439 8.25084C6.48686 7.69303 7.1264 7.18002 7.60352 6.80767C7.84028 6.62291 8.25863 6.30693 8.40301 6.19788L8.40699 6.19487C8.85169 5.86736 9.47769 5.96236 9.8052 6.40706C10.1327 6.85173 10.0377 7.47768 9.59308 7.80521C9.45995 7.90576 9.06363 8.20515 8.83397 8.38437C8.3736 8.74365 7.76313 9.23357 7.1556 9.76105C6.5431 10.2928 5.95637 10.8432 5.53062 11.3251C5.31675 11.5671 5.12116 11.8452 5.02786 12Z" fill="#151B1F"/>
            </svg>
            </div>
            <div className="pageTitle">{currentPageTitle}</div>
        </div>
        <div style={!children?{visibility:'hidden',width:44}:{}}>{children}</div>

        </div>
    );
}
export default Head;