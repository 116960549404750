import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import graph from '../images/red-graph.svg'
import { getItem } from '../utils';
import {USD } from '../utils/usdt/usdt';


const useStyles = makeStyles((theme) => ({
    root:{
        display: 'flex', justifyContent: 'flex-start', width:'100%',
        color: theme.palette.text.primary,margin:'20px',
        '& .bell-icon':{
            width: '50px',
            height: '50px',
            cursor: 'pointer',
            borderRadius: '50%',
            display:'flex',justifyContent: 'center',alignItems: 'center',
            backgroundColor: theme.palette.primary.tintMain,
        }
    },
    body:{
        display:'flex',
        justifyContent:'flex-start',flexDirection:'column', alignItems:'flex-start', gap:5,
        '& p':{
            color: theme.palette.text.secondary, fontSize:13
        },
        '& path':{
            fill: theme.palette.text.primary
        },
        '& .bal':{
            display:'flex',gap:20,
            fontWeight:500,
            '& h2':{
                minWidth:120
            }
        },
        '& .stats':{
            '& .first':{
                display:'flex',gap:5, 
                color:theme.palette.primary.main,
            },
        display:'flex',gap:10,
        color:theme.palette.primary.green,

        }
    }
       

}));

export default memo(function MainBalanceCard({WalletBalance}) {

    const [username, setUsername] = useState('');
    const [tag, setTag] = useState('');
    const [showAmt,setShowAmt] = useState(true);
    const [amt, setAmt] = useState(0);
    const [userWallet, setUserWallet] = useState({});
    const [user, setUser] = useState({});
  
    useEffect(()=>{
      const userData  = getItem('__FLASHUSDT_USER__');
      if(userData){
        setUser(userData);
      }
     if(WalletBalance){
        setAmt(WalletBalance);
    }

    },[WalletBalance])

    const classes= useStyles();

    const actualAmt = (user?.AccountType === "FREE" )? '500':amt;
    


    return(
            <div className={`${classes.root}`}>
                <div className={`${classes.body}`}>
                    <p>Asset Balance</p>
                    <div className="bal">
                        <h2>{showAmt?(USD(actualAmt)): '*****'}</h2>
                        <div tabIndex={-1} role='button' onClick={()=>setShowAmt(!showAmt)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.6394 4.92721C17.7538 3.62471 15.0525 2.25 12 2.25C8.94756 2.25 6.24625 3.62471 4.3606 4.92721C3.40826 5.58503 2.63887 6.24179 2.10629 6.73514C1.84643 6.97586 1.42164 7.41189 1.26681 7.57083L1.25502 7.58292C0.886606 7.99436 0.921484 8.62657 1.33293 8.99498C1.74409 9.36315 2.37571 9.32857 2.74424 8.91792L2.74502 8.91706C2.86755 8.7913 3.22618 8.42398 3.46543 8.20236C3.94491 7.75821 4.63998 7.16497 5.49728 6.57279C7.23092 5.37529 9.52961 4.25 12 4.25C14.4704 4.25 16.7691 5.37529 18.5027 6.57279C19.36 7.16497 20.0551 7.75821 20.5346 8.20236C20.7738 8.42398 21.1332 8.79216 21.2558 8.91792C21.6243 9.32857 22.2559 9.36315 22.6671 8.99498C23.0785 8.62657 23.1134 7.99436 22.745 7.58292L22.7331 7.57071C22.5782 7.41167 22.1535 6.9758 21.8937 6.73514C21.3612 6.24179 20.5918 5.58503 19.6394 4.92721Z" fill="#141B34"/>
<path opacity="0.4" d="M5.52031 8.71298C7.22595 7.38141 9.42944 6.25 12 6.25C14.5706 6.25 16.774 7.38141 18.4797 8.71298C20.1869 10.0458 21.502 11.694 22.2078 12.6837C22.461 13.0358 22.75 13.4378 22.75 14C22.75 14.5622 22.461 14.9642 22.2078 15.3163C21.502 16.306 20.1869 17.9542 18.4797 19.287C16.774 20.6186 14.5706 21.75 12 21.75C9.42944 21.75 7.22595 20.6186 5.52031 19.287C3.81313 17.9542 2.49805 16.306 1.79219 15.3163C1.53904 14.9642 1.25 14.5622 1.25 14C1.25 13.4378 1.53904 13.0358 1.79219 12.6837C2.49804 11.694 3.81313 10.0458 5.52031 8.71298Z" fill="#141B34"/>
<path fillRule="evenodd" clipRule="evenodd" d="M12 16C10.8954 16 10 15.1046 10 14C10 12.8954 10.8954 12 12 12C13.1046 12 14 12.8954 14 14C14 15.1046 13.1046 16 12 16ZM8 14C8 16.2091 9.79086 18 12 18C14.2091 18 16 16.2091 16 14C16 11.7909 14.2091 10 12 10C9.79086 10 8 11.7909 8 14Z" fill="#141B34"/>
</svg>
</div>

                    </div>
                    <div className="stats">
                        <div className="first">
                            <img src={graph} alt=" " />
                            <span>3.7%</span>
                        </div>
                        <span>+$2.27 (24hr)</span>
                    </div>
                </div>
        
            </div>
    );


})