import React, { useCallback, useRef, useState } from 'react';
import { styled } from '@mui/system';
import oval from '../images/oval.svg';
import { globals } from '../utils/themes';
import { makeStyles } from '@mui/styles';
import { hexToRGBA } from '../utils';

// Define your styled component
const useStyles = makeStyles((theme) => ({ 
  root:{

    display: 'flex',
    gap: '10px',
    fontSize: '1rem',
    color: '#fff',
    borderRadius:globals.borderRadius,
    height: '57px',
    backgroundColor:theme.palette.primary.main,
    border:'none',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth:350,
    fontWeight:600, cursor:'pointer',
    '&.disabled':{
      backgroundColor:theme.palette.primary.lightBlue,
      color:hexToRGBA(theme.palette.primary.main,'0.5'),
    },
    '&.secondary':{
      backgroundColor:'transparent',
      color:theme.palette.primary.main,
      border:'1px solid ' + theme.palette.primary.main,
    },
    '& path':{
      fill:theme.palette.text.primary,
    }
  }

}));

const Button = ({id, className='',text='Submit',disabled=false, icon=null, style={}, loading=false, variant='primary',onClick=()=>null, children=null, ...rest}) => {
  const classes = useStyles();

  return (
    <button style={{...style}} disabled={disabled} onClick={onClick} className={`${classes.root} ${disabled?'disabled':''} ${className} ${variant}`} {...rest}>

        {(icon && !loading) && icon}
        {loading && <img src={oval} alt=' ' id='icon'  width={21} />}

        {
          children ? children :  <span>{text}</span> 
        }

      
    </button>

  );
};

export default Button;
