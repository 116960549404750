import React, { useEffect, useRef } from 'react';
import { Html5Qrcode } from 'html5-qrcode';

const CustomQRScanner = ({ onScanSuccess, onScanFailure }) => {
  const scannerRef = useRef(null);
  const html5QrCode = useRef(null);

  useEffect(() => {
    // Initialize the QR Code scanner
    html5QrCode.current = new Html5Qrcode("qr-scanner");

    // Function to start the scanner
    const startScanner = async () => {
      try {
        await html5QrCode.current.start(
          { facingMode: "environment" }, // Use the back camera
          { fps: 10, qrbox: { width: 250, height: 250 } }, // QR box size
          onScanSuccess,
          onScanFailure
        );
      } catch (error) {
        console.error("Failed to start the scanner:", error);
      }
    };

    startScanner();

    // Cleanup on component unmount
    return () => {
      html5QrCode.current.stop().catch((error) => console.error("Failed to stop scanner:", error));
      html5QrCode.current.clear();
    };
  }, [onScanSuccess, onScanFailure]);

  return (
    <div>
      <div id="qr-scanner" style={{ width: '300px', height: '300px' }}></div>
    </div>
  );
};

export default CustomQRScanner;
