import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Head from '../../components/header';
import Input from '../../components/input';
import Button from '../../components/button';
import { CreateUserByEmail, DocumentUpload, ProfileUpdating } from '../../utils/endpoints';
import PinBox from '../../components/pinBox';
import { generateRandomNumber, getItem, handleError, sanitize, saveItem } from '../../utils';
import { useNavigate } from'react-router-dom';
import Info from '../../components/info';
import editIcon from '../../images/edit-icon.svg'
import Nav from '../../components/nav';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', width: '100%', justifyContent: 'center',
        '& .inputs':{
            width: '100%',
        },
    },
    photo: {
        position: 'relative',
        '& .photo-cont':{
            width: '120px',
            height: '120px', borderRadius: '50%', overflow: 'hidden'
        },
        '& img.image':{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        '& .edit-icon':{
            // width: '100%',
            position:'absolute', bottom: '0', right: '0', cursor:'pointer',
        }
    },
    loginSection: {
        marginTop: theme.spacing(3),
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontSize: '14px',
            fontWeight: 'bold',
        },
    },
}));

export default memo(function Profile() {
    const classes = useStyles();
    const [btnLoading, setBtnLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [FnError, setFnError] = useState('');
    const [email, setEmail] = useState('');
    const [profilePhoto, setProfilePhoto] = useState('/avater2.jpg');
    const [formData, setFormData] = useState({FirstName: '', Surname: '',Phone: ''});
    const [info, setInfo] = useState({type:'error', message:'',status:false});

    const fileInput = useRef(null);
    const rawfile = useRef(null);


    const processForm = useCallback(() => {    
        if(!btnLoading){
        if (formData.FirstName.trim().length === 0) {
            setFnError('Name cannot be blank');
            return;
        }else{
            setFnError('');
        }
        proceedSubmission();
    }

    }, [formData.FirstName,formData.Phone,formData.Surname,btnLoading]);

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            rawfile.current = e.target.files[0];
            setProfilePhoto(URL.createObjectURL(e.target.files[0]));
            setBtnDisabled(false);
        }
    };


    useEffect(() => {
        const userData = getItem('__FLASHUSDT_USER__');
        if(userData){
            //compare userData with formData
            if(userData.FirstName !== formData.FirstName || userData.Surname !== formData.Surname || userData.Phone !== formData.Phone){
                setBtnDisabled(false);
            }else{
                 setBtnDisabled(true);
            }
        }      
    }, [formData.FirstName,formData.Phone,formData.Surname]);

    useEffect(() => {
        const userData = getItem('__FLASHUSDT_USER__');
        if(userData){
            setFormData({...formData,FirstName: userData.FirstName, Surname:userData.Surname, Phone: userData.Phone});
            setEmail(userData.Email);
            setProfilePhoto(userData.Photo.trim().length < 1?`/avater${generateRandomNumber(1,6)}.jpg`:'/'+userData.Photo);
        }
    },[]);


    const proceedSubmission = useCallback( async () => {
        if(!btnLoading){
            let proceed = true
            let payload = {...formData};
            setBtnLoading(true);
            
            if (rawfile.current) {
                try {
                    const form = new FormData();
                    form.append('document',rawfile.current);
                    const uploadRes = await DocumentUpload(form);
                    if(uploadRes.status === 200){
                        payload['Photo'] = uploadRes.data.data.path;
                    }
                    
                } catch (error) {
                    setInfo({message:handleError(error),type: 'error', status: true});
                    setBtnLoading(false);
                    proceed = false;
                }
            }

        if(proceed){
            const uid = getItem('__FLASHUSDT__');
            ProfileUpdating(payload,uid).then((res) => {
                const {data} = res.data
                saveItem('__FLASHUSDT_USER__',data);
                setInfo({message:'Profile updated sucessfully',type:'success', status: true});
            }).catch((e) => {
                setInfo({message:handleError(e),type: 'error', status: true});
            }).finally(() => {
                setBtnLoading(false);
            });
        }
    }
        
    }, [rawfile.current,formData.FirstName,formData.Phone,formData.Surname,btnLoading]);



    return (
        <div style={{paddingTop:36}}>
            <Head backTo='/dashboard/settings' currentPageTitle='Profile'/>

            <div className={classes.root}>
                <div className={classes.photo}>
                    <div className='photo-cont'>
                        <img className='image' src={`${profilePhoto}`} alt='Profile Picture' />
                    </div>
                    <input ref={fileInput} hidden type="file" onChange={handleImageChange} />
                    <img className='edit-icon' src={editIcon} onClick={()=>fileInput.current.click()}/>
                </div>

                <Info type={info.type} autoRemove={true} display={info.status} setDisplay={(st)=>setInfo({...info,status:st})}>{info.message}</Info>

                {/* <div className='inputs'> */}
                    <Input id={'s3d'+1} label='First Name' showIcon={false} placeholder='Enter your firstname' value={formData.FirstName} errorMessage={FnError} onChange={(e)=>setFormData({...formData,FirstName:e.target.value})} />

                    <Input id={'s0ld'+1} label='Surname' showIcon={false} placeholder='Enter your surnname' value={formData.Surname} errorMessage={FnError} onChange={(e)=>setFormData({...formData,Surname:e.target.value})} />

                    <Input showIcon={false}  id={'swd'+2} label='Email address' placeholder='Enter your email addres' disabled value={email}/>

                    <Input showIcon={false}  id={'sdd'+3} label='Phone Number' placeholder='Your mobile number' value={formData.Phone} onChange={(e) =>setFormData({...formData,Phone:sanitize(e.target.value)})} />


                    <Button text='Save Changes' onClick={processForm} disabled={btnDisabled} loading={btnLoading}/>
                {/* </div> */}

                
            </div>
            <Nav/>
        </div>
    );
});