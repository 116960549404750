
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { globals } from '../utils/themes';
import { makeStyles } from '@mui/styles';
import { hexToRGBA } from '../utils';


const useStyles = makeStyles((theme) => ({

    wrapper:{
        borderRadius:globals.borderRadius,
        fontSize:'14px',
        cursor:'pointer',
        //padding:'0 2rem',
        margin:'1rem 0',
        width:'100%',
        display:'flex', justifyContent:'center', alignItems:'center'
    },
    root:{
        borderRadius:globals.borderRadius,
        fontSize:'14px',
        cursor:'pointer',
        padding:'20px 10px',
        width:'100%', textAlign:'center',
    },
    info: {
        backgroundColor:hexToRGBA(theme.palette.primary.main,0.13),
        color:theme.palette.primary.main,
    },
    error: {
        backgroundColor:hexToRGBA(theme.palette.primary.red,0.09), // 50% transparency
        color:theme.palette.primary.red,
    },
    success: {
        backgroundColor:hexToRGBA(theme.palette.primary.green,0.13),
        color:theme.palette.primary.green,
    },
}));

function Info({message='Message',style={}, type='info',autoRemove=false, display=false, className, setDisplay=()=>null, children}) {
   const classes = useStyles();
   const [show,setShow] = useState(display);

   useEffect(()=>{
       setShow(display);
        let timerId = 0;
        if(autoRemove){
        timerId = setTimeout(()=>{setShow(false);setDisplay(show)},10000);
        }
    return () => clearTimeout(timerId); // Clean up function to prevent memory leak when component unmounts

   },[autoRemove,display])

    return (
        show && <div  className={`${classes.wrapper}`}> 
            <div style={style} className={`${classes.root} ${classes[type]} ${className}`}> <p>{children} </p> </div>
        </div> 
    );
}
export default Info;