import React, { memo, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Head from '../../components/header';
import { getItem, handleError, useCountdown,saveItem } from '../../utils';
import { useNavigate } from'react-router-dom';
import Info from '../../components/info';
import Input from '../../components/input';
import Button from '../../components/button';
import { ProfileUpdating } from '../../utils/endpoints';
import Flyout from '../../components/flyout';

const useStyles3 = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',justifyContent:'center',
        alignItems: 'center', marginTop:'5rem', width: '100%',
        '& .cta': {
            width: '100%',
            display: 'flex', justifyContent: 'center',alignItems: 'center',

        },
        '& .inp': {
            width: '100%',
            maxWidth:'500px',
        },
    },
    titleSection: {
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        '& h3': {
            color: theme.palette.text.primary,
        },
        '& p': {
            color: theme.palette.text.secondary,
            padding: theme.spacing(1, 2),
            fontSize: '14px',
        },
    },
    loginSection: {
        marginTop: theme.spacing(3),
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontSize: '14px',
            fontWeight: 'bold',
        },
    },
}));

//reseting of password to a new one from dashboard
export default memo(function ReCreatePassword({data}){
    const classes = useStyles3();
    const [btnLoading, setBtnLoading] = useState(false);
    const [showFlyout, setShowFlyout] = useState(false);
    const [OldPwdError, setOldPwdError] = useState('');
    const [PwdError, setPwdError] = useState('');
    const [Cpwderror, setCPwdError] = useState('');
    const [formData, setFormData] = useState({Password: '',ConfirmPassword: '', oldPassword: ''});
    const [info, setInfo] = useState({type:'error', message:'',status:false});

    const navigate = useNavigate();


    const processForm = useCallback(() => {    
    if(!btnLoading){
        if (formData.oldPassword.trim().length === 0) {
            setOldPwdError('Old password is required');
            return;
        }else{
            setOldPwdError('');
        }
        if (formData.Password.trim().length === 0) {
            setPwdError('Enter a new password you which to reset to');
            return;
        }else{
            setPwdError('');
        }

        if (formData.Password.trim().length < 8) {
            setPwdError('Password should be at least 8 characters');
            return;
        }else{
            setPwdError('');
        }

        if (formData.ConfirmPassword.trim().length ===0) {
            setCPwdError('Please confirm your new password');
            return;
        }else{
            setCPwdError('');
        }

        if (formData.Password !== formData.ConfirmPassword) {
            setCPwdError('Password mismatch');
            return;
        }else{
            setCPwdError('');
        }

        proceedSubmission();
    }

    }, [formData.Password,formData.ConfirmPassword,btnLoading]);


    const proceedSubmission = useCallback( async () => {
        setBtnLoading(true);
        const payload = {
            NewPassword: formData.Password,
            OldPassword:formData.oldPassword
        }

        const uid = getItem('__FLASHUSDT__');
        
        ProfileUpdating(payload,uid).then((res) => {
            saveItem('__FLASHUSDT_USER__',res.data.data);
            setShowFlyout(true);

        }).catch((e) => {
            setInfo({message:handleError(e),type: 'error', status: true});
        }).finally(() => {
            setBtnLoading(false);
        });

    }, [formData.ConfirmPassword,btnLoading, formData.Password]);


    return (
        <>
            <Head backTo='/dashboard/security' currentPageTitle='Change Password' />

            <div className={classes.root}>

                <Info type={info.type} autoRemove={true} display={info.status} setDisplay={(st)=>setInfo({...info,status:st})}>{info.message}</Info>

                <div className='inp'>
                    <Input id={'su78wd'+2} label='Old Password' type='password' placeholder='Enter current password' value={formData.oldPassword} errorMessage={OldPwdError} onChange={(e)=>setFormData({...formData,oldPassword:e.target.value})} />

                    <Input id={'sw898d'+1} label='New Password' type='password' placeholder='Minimum of 8 character' value={formData.Password} errorMessage={PwdError} onChange={(e) =>setFormData({...formData,Password:e.target.value})} />

                    <Input id={'sd3rdd'+3} label='Confirm Password' type='password'  placeholder='Enter password again' value={formData.ConfirmPassword} errorMessage={Cpwderror} onChange={(e) =>setFormData({...formData,ConfirmPassword:e.target.value})} />

                    <div className="cta"><Button text='Submit' onClick={processForm} loading={btnLoading}/></div>
                </div>
            </div>

            {
                showFlyout && (
                    <Flyout title="Completed changed" descr={<span>Your password has been reset sucessfully.</span>}>
                        <Button text='Back to dashboard' onClick={()=>navigate('/dashboard')} />
                    </Flyout>
                )
            }
        </>
    );
});