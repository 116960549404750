import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import demo from '../images/demo.svg'
import inactive from '../images/inactive.svg'
import premium from '../images/premium.svg'
import { globals } from '../utils/themes';
import { getItem } from '../utils';
import { useNavigate } from 'react-router-dom';
import { commarize, USD } from '../utils/usdt/usdt';


const useStyles = makeStyles((theme) => ({
    root:{
        display: 'flex',alignItems: 'center',  justifyContent: 'space-between', width:'100%',
        backgroundColor: theme.palette.primary.tintMain, padding:'20px 30px',borderRadius: globals.borderRadius, color: theme.palette.text.primary,
    },
    usercard:{
        display:'flex',
        justifyContent:'space-between',alignItems: 'center',  gap:10,
        '& .photo':{
            width: '70px',
            height: '70px', borderRadius: '50%', overflow: 'hidden',
            '& img':{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transform:'scale(1.1)'
            }
        },
        '& .info':{
            color: theme.palette.text.primary, fontWeight:500,
            fontSize:'clamp(0.5rem,4vw,1rem)',
        }
    }
       

}));

export default memo(function CoinAmtCard({WalletData, withCont = false}) {
    const classes= useStyles(); 
    return(
            <div className={`${classes.root} ${withCont? classes['withbg'] : ''}`}>
                <div className={classes.usercard}>
                    <div className='photo'>
                       { WalletData?.logo && <img src={`/${WalletData.logo}`} alt='Wallet logo'/>}
                    </div>
                    <div className='info'>
                        <p>{WalletData?.name } {WalletData?.symbol && (WalletData.symbol)}</p>
                        <h2>{commarize(WalletData?.token  || 0, 2,18)}</h2>
                        <div>{USD(WalletData?.usd||0)}</div>
                      
                    </div>
                </div>

               
        
            </div>
    );


})