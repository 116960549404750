import React, { memo, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Head from '../components/header';
import {PasswordResetRequest, PinResending, ValidatePasswordResetPin, VerifyEmail } from '../utils/endpoints';
import { getItem, handleError, useCountdown,saveItem } from '../utils';
import { useNavigate } from'react-router-dom';
import Info from '../components/info';
import Input from '../components/input';
import Button from '../components/button';
import { CreateUserByEmail, ProfileUpdating } from '../utils/endpoints';
import PinBox from '../components/pinBox';
import Flyout from '../components/flyout';
import Spinner from '../components/spinner';


export default  memo(function ResetPasswordFlows() {
    const [Page, setPage] = useState({id:3,data:null});
    
    return(
        Page.id === 3 ? (<ResetPasswordEmail setNextPage={setPage}/>):
        Page.id === 2 ? (<ResetPassword setNextPage={setPage}/>):
        Page.id === 1 ?( <SetUpNewPassword setNextPage={setPage} data={Page.data}/>):null
    );
});
    

// ----------------------------------------------------------------


const useStyles2 = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',marginTop:'3rem',
        '& .full-width':{
            width: '100%', display: 'flex',flexDirection: 'column',alignItems: 'center', justifyContent: 'center',
        }
    },
    titleSection: {
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        '& h3': {
            color: theme.palette.text.primary,
        },
        '& p': {
            color: theme.palette.text.secondary,
            padding: theme.spacing(1, 2),
            fontSize: '14px',
        },
    },
    loginSection: {
        marginTop: theme.spacing(10),color: theme.palette.text.primary,
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontSize: '16px',
            fontWeight: 'bold',
        },
    },
}));

export const ResetPasswordEmail = ({setNextPage})=> {
    const classes = useStyles2();
    const [emailError, setEmailError] = useState('');
    const [email, setEmail] = useState('');

    const handleChange = useCallback((e) => {
        setEmail(e.target.value);
    }, []);

    const processEmail = useCallback(() => {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        if (!emailRegex.test(email)) {
            setEmailError('Invalid email address');
        } else {
            setEmailError('');
            proceedSubmission();
        }
    }, [email]);

    const proceedSubmission = useCallback( async () => {
        const uid = getItem('__FLASHUSDT__');
        PasswordResetRequest({Email: email},uid).then((res) => {
            setNextPage({id:2,data:null});
        }).catch((e) => {
            setEmailError(handleError(e));
        });
     
    }, [email]);



    return (
        <>
            <Head backTo='/signin' currentPageTitle='Forget Password' />

            <div className={classes.root}>
                <div className={classes.titleSection}>
                    <h3>Reset Password</h3>
                    <p>We will send you a verification code <br/> via email to reset password.</p>
                </div>

                <div className='full-width'>
                    <Input label='Email address' type='email' value={email} errorMessage={emailError} onChange={handleChange} />

                    <Button text='Send Code' onClick={processEmail} />
                </div>
            </div>
        </>
    );
};



// ----------------------------------------------------------------


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex', marginTop: '60px',
        flexDirection: 'column',
        alignItems: 'center', marginTop:'5rem',
        '& .full-width':{
            width: '100%', display: 'flex',flexDirection: 'column',alignItems: 'center', justifyContent: 'center',
        }
    },
    titleSection: {
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        '& h3': {
            color: theme.palette.text.primary,
        },
        '& p': {
            color: theme.palette.text.secondary,
            padding: theme.spacing(1, 2),
            fontSize: '14px',
        },
    },
    loginSection: {
        marginTop: theme.spacing(10),color: theme.palette.text.primary,
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontSize: '14px',
            fontWeight: 'bold',
        },
    },
}));
export const ResetPassword= ({setNextPage})=>{
    const classes = useStyles();
    const navigate = useNavigate();
    const [showLoader, setShowLoading] = useState(false);


    const [info, setInfo] = useState({type:'error', message:'',status:false});
    const [pinValue, setPinValue] = useState('');

    useEffect(() => {
        const uid = getItem('__FLASHUSDT__');
        if(!uid) navigate('/signup');

        if(pinValue.length ===4) {
            proceedSubmission(uid);
        }
        
    },[pinValue]);

    const proceedSubmission = useCallback( async (uid) => {    
        setShowLoading(true);    
        ValidatePasswordResetPin({Pin: pinValue},uid).then((res) => {
            setNextPage({id:1,data:res.data?.data?.ResetToken});
            
        }).catch((e) => {
            setInfo({...info,status:true,message:handleError(e)});
        }).finally(() => {
            setShowLoading(false);
        });
     
    },[pinValue]);

    const ResendPin = useCallback( async () => {
        setShowLoading(true);
        const uid = getItem('__FLASHUSDT__');
        PinResending(uid).then((res) => {
            setStatus(false);
            setInfo({...info,status:true,message:'Pin resent successfully',type:'success'});
        }).catch((e) => {
            setInfo({...info,status:true,message:handleError(e)});
        }).finally(() => {
            setShowLoading(false);
        });
     
    },[pinValue]);

    const {setStartTimestamp, status, timeLeft, setStatus} = useCountdown();
    // const []= use
    useEffect(()=>{
        if(!status){
            setStartTimestamp(new Date().getTime() + 1 * 60 * 1000); // 1 minute
        }
        
    },[status])

    return (
        <>
            <div className={classes.root}>
            
                <div className={classes.titleSection}>
                    <h3>Enter Reset Pin</h3>
                    <p>Enter the 4-digit code sent to you</p>
                </div>

            <Info type={info.type} autoRemove={true} display={info.status} setDisplay={(st)=>setInfo({...info,status:st})}>{info.message}</Info>

                <div>
                    <PinBox getValue={setPinValue}/>
                </div>

                <div className={classes.loginSection}>
                    <p>Didn't get code? <a>
                    
                     {
                        status?
                        <span tabIndex={-1} role='button' onClick={ResendPin}> &nbsp; Resend</span>:

                        <span>Resend in ({timeLeft.secs} secs)</span>
                    }
                     
                     </a>

                     </p>
                </div>
            </div>
            {showLoader && <Spinner/>}
        </>
    );

}




// ----------------------------------------------------------------



const useStyles3 = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', marginTop:'5rem',
        '& .full-width':{
            width: '100%', display: 'flex',flexDirection: 'column',alignItems: 'center', justifyContent: 'center',
        }
    },
    titleSection: {
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        '& h2': {
            color: theme.palette.text.primary,
        },
        '& p': {
            color: theme.palette.text.secondary,
            padding: theme.spacing(1, 2),
            fontSize: '14px',
        },
    },
    loginSection: {
        marginTop: theme.spacing(3),
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontSize: '14px',
            fontWeight: 'bold',
        },
    },
}));

export const SetUpNewPassword = ({setNextPage,data})=> {
    const classes = useStyles3();
    const [btnLoading, setBtnLoading] = useState(false);
    const [showFlyout, setShowFlyout] = useState(false);
    const [PwdError, setPwdError] = useState('');
    const [Cpwderror, setCPwdError] = useState('');
    const [formData, setFormData] = useState({Password: '',ConfirmPassword: ''});
    const [info, setInfo] = useState({type:'error', message:'',status:false});

    const navigate = useNavigate();


    const processForm = useCallback(() => {    
    if(!btnLoading){
        if (formData.Password.trim().length === 0) {
            setPwdError('Create a password');
            return;
        }else{
            setPwdError('');
        }

        if (formData.Password.trim().length < 8) {
            setPwdError('Password should be at least 8 characters');
            return;
        }else{
            setPwdError('');
        }

        if (formData.ConfirmPassword.trim().length ===0) {
            setCPwdError('Please confirm your password');
            return;
        }else{
            setCPwdError('');
        }

        if (formData.Password !== formData.ConfirmPassword) {
            setCPwdError('Password mismatch');
            return;
        }else{
            setCPwdError('');
        }

        proceedSubmission();
    }

    }, [formData.Password,formData.ConfirmPassword,btnLoading]);


    const proceedSubmission = useCallback( async () => {
        setBtnLoading(true);
        const payload = {
            NewPassword: formData.Password,
            ResetToken:data
        }
        const uid = getItem('__FLASHUSDT__');
        
        ProfileUpdating(payload,uid).then((res) => {
            // const id = res.data?.data?.userId;
            setShowFlyout(true);
            
            setBtnLoading(false);
        }).catch((e) => {
            setBtnLoading(false);
            setInfo({message:handleError(e),type: 'error', status: true});
        })
        // setBtnLoading(false);
    }, [formData.ConfirmPassword,btnLoading, formData.Password]);


    return (
        <>
            <Head backTo='/signin' currentPageTitle='Create Password' />

            <div className={classes.root}>
                <div className={classes.titleSection}>
                    <h2>Create new password</h2>
                    <p>Set a new password you will remember</p>
                </div>

                <Info type={info.type} autoRemove={true} display={info.status} setDisplay={(st)=>setInfo({...info,status:st})}>{info.message}</Info>

                <div className='full-width'>
                    <Input  id={'swd'+2} label='Create Password' type='password' placeholder='Minimum of 8 character' value={formData.Password} errorMessage={PwdError} onChange={(e) =>setFormData({...formData,Password:e.target.value})} />

                    <Input id={'sdd'+3} label='Confirm Password' type='password'  placeholder='Enter password again' value={formData.ConfirmPassword} errorMessage={Cpwderror} onChange={(e) =>setFormData({...formData,ConfirmPassword:e.target.value})} />


                    <Button text='Submit' onClick={processForm} loading={btnLoading}/>
                </div>
            </div>

            {
                showFlyout && (
                    <Flyout title="Completed" descr={<span>Your password has been created. <br/> Continue to sign in</span>}>
                        <Button text='Sign in' onClick={()=>navigate('/signin')} />
                    </Flyout>
                )
            }
        </>
    );
};