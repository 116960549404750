import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Head from '../../components/header';
import Button from '../../components/button';
import Input from '../../components/input';




const useStyles = makeStyles((theme) => ({
    root:{
        overflowY: 'scroll',
        display:'flex', alignItems: 'center', flexDirection: 'column',
        justifyContent:'center',gap:20, marginTop:'2rem'
    },
    info__:{
        fontSize: '12px'
    }
 
       

}));


  

export default memo(function ImportWallet({setUserTheme=()=>null}) {
    const [inputMessage, setInputMessage] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [btnLoading, setBtnLoading] = useState('');
    const classes= useStyles();
    // const nav =useNavigate();


    const processForm=useCallback(()=>{
        if(inputValue.trim().length < 10){
            setInputMessage("Invalid phrase");
        }else{
            setInputMessage("");
            // submitCode();
        }        
    },[inputValue])



    return(
        <div style={{paddingTop:36}}>
            <Head backTo='/dashboard/settings' currentPageTitle='Import wallet'/>

            <div className={`${classes.root}`}>
                <Input  id={'4ed3c32'} label='Secret Phrase' value={inputValue} onChange={(e)=>setInputValue(e.target.value)} placeholder='Paste your mneomonic phrase here' showIcon={false} errorMessage={inputMessage}/>
                <Button text='Import wallet'  onClick={processForm} loading={btnLoading} />
            </div>
        </div>
    );


})