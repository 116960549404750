import React, {useMemo, useRef } from "react";
import { globals } from '../utils/themes';
import { makeStyles } from "@mui/styles";
import { hexToRGBA } from "../utils";


//Pin Verification Input (PVI)
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex", justifyContent: "center", alignItems: "center", gap: 10,
        "& > input:focus": { backgroundColor: hexToRGBA(theme.palette.primary.main,0.1) },
        "& > input": {
            width: 70, height: 58,
            textAlign: "center",
            fontSize: "1.5rem", border: "thin solid " + theme.palette.primary.main,
            color: theme.palette.primary.main,
            borderRadius: globals.borderRadius,
            transition: "outline 0.2s ease",
            outline: "5px solid transparent", backgroundColor:'transparent', cursor: 'pointer',
            caretColor: 'transparent',
        },
        "& input[type=number]": {
          "-moz-appearance": "textfield",
        },
        '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button': {
          "-webkit-appearance": "none", margin:0,
        },
      },
      password:{
        fontSize: "3rem !important", 
      }
}));
function PinBox({boxes=4, getValue=()=>void(0), className='', type="text"}){

    const classes = useStyles();
    const parent = useRef(null);
    // const [pin, setPin] = useState('');
    const setPinByField = (field)=>{
      let fields = field.parentElement.children;
      let pPin = '';
      for(let i = 0; i<fields.length; i++){
        // fields[i].value=nums[i]; fields[i].focus();
        pPin += fields[i].value;
      }
      getValue(pPin);
    }
  const handleInputs = (field) => {

    
    field = field.target;
    if (!/^[0-9]*$/.test(field.value)) {
      field.value = '';
    } else {
      if (field.value.length === 1) {
        if (field.nextElementSibling) {
          field.nextElementSibling.focus();
        }
      }
      if (field.value.length > 1) {
        field.value = field.value.slice(0, 1);
      }
    }

    if (field.type === 'number' && type === 'password') {
      if (field.value.length === 1) {
        field.type ='password';
      } else {
        field.type = 'number';
      }
    }

    setPinByField(field);
  }
       

    const handlePaste=(e)=>{
        // e = window.event;
        e.stopPropagation();
        e.preventDefault();
        
    //    let clipboardData = e.clipboardData || window.clipboardData;
       let clipboardData = e.clipboardData;
        let pastedData = clipboardData.getData('Text');
    
        if(Number.isInteger(+pastedData)){
            let str = pastedData.toString();
            if(str.length > 0){
              let nums = str.split('');
            let fields = e.target.parentElement.children;
            let pPin = '';
            for(let i = 0; i<nums.length; i++){
              fields[i].value=nums[i]; fields[i].focus();
              pPin += nums[i];
              if(i===fields.length-1)break;
            }
            // setPin(pPin);
            getValue(pPin);
          }
        }
        
    }

    const handleArrowMovement=(e)=>{
        let box = e.target;

        if(e.keyCode === 37){
        if (box.type === 'number')return;
        if(!box.previousElementSibling)return; 
        box.previousElementSibling.focus();
        box.selectionStart = box.selectionEnd = box.value.length;
      } else if(e.keyCode === 39){
        if (box.type === 'number')return;
          if(!box.nextElementSibling)return; 
            box.nextElementSibling.focus();
      }
      
      if(e.keyCode === 8){
        e.preventDefault();
        if(!box.previousElementSibling) return box.value = '';
        box.value = '';
        box.previousElementSibling.focus();
         
      }else if(e.keyCode === 46){
        e.preventDefault();
        if(!box.nextElementSibling) return box.value = '';
        box.value = '';
        box.nextElementSibling.focus();   
    }

    if(box.value.length >=1 && box.nextElementSibling && e.keyCode !==37 && e.keyCode !==39){
        box.nextElementSibling.focus();
    }
    setPinByField(box);
}

  const setType = (box, inverse = false) => {
  
    
    if (box.type === 'password' && type === 'password') {
      console.log(type);
      if (box.value === '') {
        box.type ='number';
      } else {
        box.type = 'password';
      }

    }
  }

  const setType_blur = (box) => {
    if (box.type === 'number' && type === 'password') {
      console.log(type);
      if (box.value === '') {
        box.type = 'number';
      } else {
        box.type ='password';
      }
    }

  }












    let pvis = useMemo(()=>Array.from(Array(boxes).keys()),[boxes]);
    return( <div ref={parent} className={`${classes.root} ${className || ''}`}>
        {
            pvis.map(box=><input key={box} type={type} className={classes[type]||''} maxLength={1} onKeyDown={(e)=>handleArrowMovement(e)} onPaste={(e)=>handlePaste(e)} onInput={(e)=>{handleInputs(e)}} onFocus={(e)=>setType(e.target)} onBlur={(e)=>setType_blur(e.target)}/>)

        }
  </div>);
}

export default PinBox;