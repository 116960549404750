import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Head from '../../components/header';
import Button from '../../components/button';
import atth from '../../images/attachment.svg';
import Flyout from '../../components/flyout';




const useStyles = makeStyles((theme) => ({
    root:{
        overflowY: 'scroll',
        display:'flex', alignItems: 'center', flexDirection: 'column',
        justifyContent:'center',gap:20, marginTop:'2rem'
    },
    info__:{
        fontSize: '12px'
    },
    uploader:{
        width: '100%',
        maxWidth:'500px',
        height: '200px',
        borderRadius: '16px',
        backgroundColor: theme.palette.primary.tintMain,
        border:'1px solid '+theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        flexDirection:'column',
        marginBottom: 20,
        '& .logo': {
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            padding: '10px',
            backgroundColor: theme.palette.background.default,
            marginBottom: 10,
            '& img':{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
            }
        },
        '& h5':{
            color:theme.palette.text.primary, marginBottom:5,
        },
        '& p':{
            color:theme.palette.text.secondary, fontSize:15,
        },
        '& small':{
            color:theme.palette.text.secondary,
        }
    }
 
       

}));


  

export default memo(function PluginUpload({setUserTheme=()=>null}) {
    const [showFlyout, setShowFlyout] = useState(false);
    const [inputMessage, setInputMessage] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [btnLoading, setBtnLoading] = useState('');
    const classes= useStyles();
    const nav =useNavigate();


    const processForm=useCallback(()=>{
        setShowFlyout(true);
    },[])



    return(
        <div style={{paddingTop:36}}>
            <Head backTo='/dashboard/settings' currentPageTitle='Upload Plugin'/>

            <div className={`${classes.root}`}>
                <label htmlFor='b3j46' className={`${classes.uploader}`}>
                    <div className='logo'><img src={atth} alt="attachment icon" /></div>
                    <h5>Tag to upload your file</h5>
                    <small>Only zipped files are supported</small>
                    <small>(not more than 10mb)</small>
                </label>
                <input type="file" name="plugin" id="b3j46" hidden/>
                <Button text='Upload' disabled={true} onClick={processForm} loading={btnLoading} />
            </div>

            {showFlyout && (
                <Flyout title='Plugin uploaded' descr="Pluggin have been uploaded. You'll be notified shortly.">
                    <Button text='Back to settings'  onClick={()=>nav('/dashboard/settings')} loading={btnLoading} />
                </Flyout>)
            }

        </div>
    );


})