import { createTheme } from "@mui/material";

export const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#116EFC',// blue
        red:'#F83446',
        green:'#009A51',
        grey:'#98A2B3',
        stroke:'#E4E7EC',
        lightBlue:'#E7F1FF',
        tintMain:'#F9FAFB'
      },
      secondary: {
        main: '#EDA833', 
      },
      background: {
        default: '#ffffff', // White
      },
      text: {
        primary: '#101828', // Black
        secondary: '#98A2B3', // Gray
      },
      icon: {
        main: '#92BCFE',
      }
     
    },
  });
  
  export const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#116EFC',
        red:'#F83446',
        green:'#009A51',
        grey:'#667085',
        stroke:'#667085',
        lightBlue:'#B5D2FE',
        tintMain:'#1D2939'
      },
      secondary: {
        main: '#EDA833',
      },
      background: {
        default: '#101828', 
      },
      text: {
        primary: '#ffffff', 
        secondary: '#aaaaaa',
      },
      icon: {
        main: '#1D2939',
      }
    },
  });
  
  export const globals = {
    borderRadius:'16px'
  }
  