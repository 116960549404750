import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import demo from '../images/demo.svg'
import inactive from '../images/inactive.svg'
import premium from '../images/premium.svg'
import { globals } from '../utils/themes';
import { getItem } from '../utils';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root:{
        display: 'flex',alignItems: 'center',  justifyContent: 'space-between', width:'100%',
        '& .bell-icon':{
            width: '50px',
            height: '50px',
            cursor: 'pointer',
            borderRadius: '50%',
            display:'flex',justifyContent: 'center',alignItems: 'center',
            backgroundColor: theme.palette.primary.tintMain,
        }
    },
    withbg:{
        backgroundColor: theme.palette.primary.tintMain,
        padding:'20px 30px',borderRadius: globals.borderRadius,
    },
    usercard:{
        display:'flex',
        justifyContent:'space-between',alignItems: 'center',  gap:10,
        '& .photo':{
            width: '40px',
            height: '40px', borderRadius: '50%', overflow: 'hidden',
            '& img':{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
            }
        },
        '& h4':{
            color: theme.palette.text.primary, fontWeight:500
        }
    }
       

}));

export default memo(function UserCard({icon=null, withCont = false}) {

    const [username, setUsername] = useState('');
    const [tag, setTag] = useState('');
    const [photo, setPhoto] = useState('');
    const nav = useNavigate();
  
    useEffect(()=>{
      const userData  = getItem('__FLASHUSDT_USER__');
      if(userData){
          setUsername(userData.FirstName + ' ' + userData.Surname); 
          setTag(userData.AccountType);
          setPhoto(userData.Photo);
      }
    },[])

    const classes= useStyles();
    const userTag = tag === 'PREMIUM'? premium:tag==='DEMO'?demo:inactive;

    return(
            <div className={`${classes.root} ${withCont? classes['withbg'] : ''}`}>
                <div className={classes.usercard}>
                    <div className='photo'>
                        <img src={`/${photo}`} alt='User Profile'/>
                    </div>
                    <div className='info'>
                        <h4>{username}</h4>
                       <img src={userTag} alt="user tag"/>
                    </div>
                </div>

                <div className={classes.ctaIcon}>
                   {icon?icon:(<div tabIndex={-1} role='button' onClick={()=> nav('/dashboard/notification')} className='bell-icon'><svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.92773 8.27697C1.92777 3.84151 5.54406 0.25 9.99988 0.25C14.4557 0.25 18.072 3.84155 18.072 8.27703C18.0721 9.30878 18.1415 10.0874 18.6172 10.7873C18.7983 11.0498 19.1412 11.4936 19.3695 11.8505C19.6254 12.2503 19.8753 12.7324 19.9612 13.2942C20.2415 15.127 18.9493 16.3136 17.6624 16.8454C13.1297 18.7182 6.87004 18.7182 2.33734 16.8454C1.05043 16.3136 -0.24176 15.127 0.0385538 13.2942C0.124472 12.7324 0.374399 12.2503 0.630212 11.8505C0.858609 11.4936 1.20156 11.0497 1.38259 10.7872C1.85826 10.0873 1.92764 9.30865 1.92773 8.27697Z" fill="#116EFC"/>
                    <path opacity="0.4" d="M12.7775 20.9511C11.9741 21.4563 11.0186 21.7477 9.99834 21.7477C8.97808 21.7477 8.0226 21.4563 7.21922 20.9511C6.50617 20.5027 6.14965 20.2784 6.27441 19.9057C6.39918 19.533 6.89114 19.5747 7.87506 19.6582C9.28224 19.7776 10.7144 19.7776 12.1216 19.6582C13.1055 19.5747 13.5975 19.533 13.7223 19.9057C13.847 20.2784 13.4905 20.5027 12.7775 20.9511Z" fill="#116EFC"/>
                    </svg></div>
                    )}
                   
                </div>
        
            </div>
    );


})