import React, { memo,useCallback,useEffect,useRef,useState } from "react";
import handle from "../images/NAV-Toggle.svg";
import home from "../images/home.svg";
import wallet from "../images/wallet2.svg";
import tx from "../images/history.svg";
import settings from "../images/user-settings.svg";
import { makeStyles } from "@mui/styles";
import { hexToRGBA } from "../utils";
import { useNavigate } from "react-router-dom";
import { globals } from "../utils/themes";



const useStyles = makeStyles((theme) => ({
    backdrop:{
       position: "fixed", display: "flex", justifyContent: "center",alignItems: "center", height: "100vh", backgroundColor:'#1D394770',backdropFilter:'blur(3px)',top:0,left:0,width:'100%', padding:'30px'
    },
    body: {
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:theme.palette.background.default,
        borderRadius: globals.borderRadius,
        padding: '20px', position: 'relative',
        width:'100%',minHeight: '200px', maxWidth:500,
        '& .content':{
            display:'flex', alignItems: 'center', flexDirection: 'column',
            justifyContent:'center', width:'100%',
            gap:10,
        },
        '& .close-btn':{
            display: 'flex',justifyContent: 'center',alignItems: 'center', padding: '13px',backgroundColor:theme.palette.primary.tintMain,borderRadius: '50px',cursor: 'pointer',
            position:'absolute',top:15,right:15,
            '& path':{
               fill:theme.palette.text.primary
            }
            
        },
    }

       

}));


export default memo( function PopUp({display=false, className, setDisplay=()=>null,children=<></>}){
    const classes = useStyles();
    const [isNavVisible, setIsNavVisible] = useState(false);
    const el = useRef(null);
    const nav = useNavigate();
    const [show,setShow] = useState(display);

    const RemovePopup = useCallback(()=>{
        setShow(!show);
        setDisplay(show);
 
    },[display])
    
    useEffect(()=>{
        setShow(display);
 
    },[display])



    return (
        <>
           { show && <div className={`${classes.backdrop} animated fadeIn`} >
                <div className={`${classes.body} ${className} animated bounceIn`}>
                    <div className="close-btn" tabIndex={-1} role="button" onClick={RemovePopup}>
                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.22034 0.22034C0.290009 0.150495 0.372772 0.0950809 0.46389 0.0572712C0.555008 0.0194616 0.652689 0 0.75134 0C0.849991 0 0.947673 0.0194616 1.03879 0.0572712C1.12991 0.0950809 1.21267 0.150495 1.28234 0.22034L5.25134 4.19084L9.22034 0.22034C9.29007 0.150608 9.37286 0.0952938 9.46396 0.0575552C9.55507 0.0198166 9.65272 0.000392675 9.75134 0.000392675C9.84996 0.000392675 9.94761 0.0198166 10.0387 0.0575552C10.1298 0.0952938 10.2126 0.150608 10.2823 0.22034C10.3521 0.290072 10.4074 0.372856 10.4451 0.463965C10.4829 0.555074 10.5023 0.652725 10.5023 0.75134C10.5023 0.849956 10.4829 0.947607 10.4451 1.03872C10.4074 1.12982 10.3521 1.21261 10.2823 1.28234L6.31184 5.25134L10.2823 9.22034C10.3521 9.29007 10.4074 9.37286 10.4451 9.46396C10.4829 9.55507 10.5023 9.65272 10.5023 9.75134C10.5023 9.84996 10.4829 9.94761 10.4451 10.0387C10.4074 10.1298 10.3521 10.2126 10.2823 10.2823C10.2126 10.3521 10.1298 10.4074 10.0387 10.4451C9.94761 10.4829 9.84996 10.5023 9.75134 10.5023C9.65272 10.5023 9.55507 10.4829 9.46396 10.4451C9.37286 10.4074 9.29007 10.3521 9.22034 10.2823L5.25134 6.31184L1.28234 10.2823C1.21261 10.3521 1.12982 10.4074 1.03872 10.4451C0.947607 10.4829 0.849956 10.5023 0.75134 10.5023C0.652725 10.5023 0.555074 10.4829 0.463965 10.4451C0.372856 10.4074 0.290072 10.3521 0.22034 10.2823C0.150608 10.2126 0.0952938 10.1298 0.0575552 10.0387C0.0198166 9.94761 0.000392675 9.84996 0.000392675 9.75134C0.000392675 9.65272 0.0198166 9.55507 0.0575552 9.46396C0.0952938 9.37286 0.150608 9.29007 0.22034 9.22034L4.19084 5.25134L0.22034 1.28234C0.150495 1.21267 0.0950809 1.12991 0.0572712 1.03879C0.0194616 0.947673 0 0.849991 0 0.75134C0 0.652689 0.0194616 0.555008 0.0572712 0.46389C0.0950809 0.372772 0.150495 0.290009 0.22034 0.22034Z" fill="#151B1F"/>
</svg>
                    </div>
<div className="content">{children}</div>
                </div>
             </div>
}
</>
    )
});