import React, { memo,useCallback,useEffect,useRef,useState } from "react";
import handle from "../images/NAV-Toggle.svg";
import home from "../images/home.svg";
import wallet from "../images/wallet2.svg";
import tx from "../images/history.svg";
import settings from "../images/user-settings.svg";
import { makeStyles } from "@mui/styles";
import { globals } from "../utils/themes";
import { hexToRGBA } from "../utils";
import { useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";



const useStyles = makeStyles((theme) => ({
    root:{
       position: "fixed", display: "flex", flexDirection:'column', justifyContent: "center",alignItems: "center",gap:10,
       bottom: '5%',left: '50%',transform: 'translateX(-50%)',
       '& .knob':{
        cursor: 'pointer', display:'flex', boxShadow:'3px 10px 30px #00000080', borderRadius:'50%',
       }
    },
    tabs: {
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:theme.palette.primary.main,
        borderRadius: '50px',
        padding: '10px',
        '& > div':{
            display: 'flex',justifyContent: 'center',alignItems: 'center', padding: '13px',backgroundColor:hexToRGBA('#0F64E5',100),borderRadius: '50px',cursor: 'pointer',
            '& img':{
                width: '25px',
                height: '25px',
                
            }
        },
    }

       

}));


export default memo( function Nav(){
    const classes = useStyles();
    const [isNavVisible, setIsNavVisible] = useState(false);
    const el = useRef(null);
    const nav = useNavigate();

    const toggleNav = ()=> {
        setIsNavVisible(!isNavVisible);
    };


    return (
                <div className={classes.root}>
            {isNavVisible && (<div ref={el} className={`${classes.tabs} animated fadeInUp faster`}>
                <div title="Dashboard">
                    <img src={home} alt="Tab"  tabIndex={-1} role="button" onClick={()=>nav('/dashboard')}/>
                </div>
                <div title="Coins">
                    <img src={wallet} alt="Tab" tabIndex={-1} role="button" onClick={()=>nav('/dashboard/coins')} />
                </div>
                <div title="Transactions">
                    <img src={tx} alt="Tab" tabIndex={-1} role="button" onClick={()=>nav('/dashboard/txn/usdt')}/>
                </div>
                <div title="Ettings">
                    <img src={settings} alt="Tab" tabIndex={-1} role="button" onClick={()=>nav('/dashboard/settings')}/>
                </div>
            </div>)
            }
            
            <div className="animated knob fadeInUp faster" tabIndex={-1} role="button" onClick={toggleNav} >
                <img src={handle} alt="Toggle"  width={55} />
            </div>

        </div>
        
    )
});