import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Head from '../../components/header';
import { getItem, hexToRGBA } from '../../utils';
import chevronRight from '../../images/chevron-right.svg';
// import chevronRight from './images/call-us.svg';
import changePassword from './images/change-password.svg';
// import chevronRight from './images/mail.svg';
// import chevronRight from './images/notification.svg';
// import chevronRight from './images/telegram.svg';
import walletSettings from './images/wallet-settings.svg';
import { globals } from '../../utils/themes';
import { darkTheme, lightTheme } from "../../utils/themes";
import Switch from '@mui/material/Switch';
import Nav from '../../components/nav';




const useStyles = makeStyles((theme) => ({
    root:{
        // overflowY: 'scroll', height:'calc(100vh - 100px)', 
    },
    setting:{
        display:'flex', alignItems: 'center',
        justifyContent:'space-between', width:'100%', backgroundColor: theme.palette.primary.tintMain,
        padding:'20px 20px',borderRadius: globals.borderRadius, marginBottom:15,
        
        '& span':{
            color: theme.palette.text.primary, fontWeight:500, fontSize:13,
        },
        '& p':{
            color: theme.palette.text.secondary,fontSize:11, padding:'4px 0'
        },
        '& .left':{
            alignItems: 'center', justifyContent:'space-between',gap:20, display:'flex',
            '& .icon-cont':{
                display:'flex', alignItems: 'center', justifyContent:'center',
                padding: '7px',borderRadius: '20px', backgroundColor: hexToRGBA(theme.palette.background.default,0.3)
            }
        },
        
    }
       

}));


  

export default memo(function SecuritySettings({setUserTheme=()=>null}) {
    // const [settings, setSettings] = useState([])
    const [username, setUsername] = useState('');
    const classes= useStyles();
    const nav =useNavigate();

    useEffect(()=>{
        const userData  = getItem('__FLASHUSDT_USER__');
        if(userData){
            setUsername(userData.FirstName + ' ' + userData.Surname);            
        }
    },[])

    return(
        <div style={{paddingTop:36}}>
            <Head backTo='/dashboard/settings' currentPageTitle='Security Settings'/>

            <div className={`${classes.root}`}>

            
                <div className={`${classes.setting} hide`} tabIndex={-1} onClick={()=>nav('/dashboard/phrase')} role='button'>
                    <div className='left'>
                        <div className='icon-cont'>
                            <img src={walletSettings} alt=" " />
                        </div>
                        <div>
                            <span>Wallet</span>
                            <p>Manage wallet secret phrase</p>
                        </div>
                    </div>
                    <img src={chevronRight} alt="" />
                </div>


                <div className={`${classes.setting}`} tabIndex={-1} onClick={()=>nav('/dashboard/reset-password')} role='button'>
                    <div className='left'>
                        <div className='icon-cont'>
                            <img src={changePassword} alt=" " />
                        </div>
                        <div>
                            <span>Manage password</span>
                            <p>Change password & 2FA settings</p>
                        </div>
                    </div>
                    <img src={chevronRight} alt="" />
                </div>



            </div>
            <Nav/>
        </div>
    );


});