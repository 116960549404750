import Web3 from 'web3';
import networks from '../networks';
import { decryptWallet, encryptWallet, getItem } from '..';
import { formattedPrivateKey } from '../tron/tron';
const { hdkey } = require('ethereumjs-wallet');
const bip39 = require('bip39');

// Create Ethereum Wallet
const { rpcUrl, aggregators: { chainlink: { trx_usd, eth_usd } } } = networks[process.env.REACT_APP_NETWORK];
// export const infuraProjectId ="http://127.0.0.1:8545";
export const web3 = new Web3(rpcUrl);

// Create Ethereum Wallet
export const createEthereumWallet = () => {
  // Step 1: Generate a Mnemonic and Derive the Wallet
  const mnemonic = bip39.generateMnemonic();

  // Derive a seed from the mnemonic
  const seed = bip39.mnemonicToSeedSync(mnemonic);

  // Generate HD wallet from the seed
  const hdWallet = hdkey.fromMasterSeed(seed);
  const path = "m/44'/60'/0'/0/0"; // Standard Ethereum derivation path
  const wallet = hdWallet.derivePath(path).getWallet();

  // Get the private key and address
  const privateKey = wallet.getPrivateKeyString();
  // const address = `0x${wallet.getAddress().toString('hex')}`;

  let acc = web3.eth.accounts.privateKeyToAccount(privateKey);
  acc['mnemonic'] = mnemonic;

  return encryptWallet(acc);
};

// export const decryptWallet = (encryptedWallet)=>{
//   const decryptedWallet = web3.eth.accounts.decrypt(encryptedWallet, process.env.REACT_APP_ENC_PWD);
//   return decryptedWallet;
// }

//get ERC20 balance  = eth
export const checkERC20Balance = async (address) => {
  const balance = await web3.eth.getBalance(address);
  const token =  web3.utils.fromWei(balance, 'ether');

  const rate = await getEthToUsdRate();
  const usd = +token * rate;
  return { token, rate, usd };
};

export const sendTransaction = async (to, amount, onSuccess) => {
  try {

    const w = getItem('__FLASHUSDT_U.WALLET__');
    if(!w)throw new Error('User wallet not found');
    const selected = w.All.filter((t)=> t.Type === 'ETH')[0];
    const {address, privateKey} = decryptWallet(selected.Wallet);

    let nonce = await web3.eth.getTransactionCount(address, 'latest');

    const tx = {
      from: address,
      to: to,
      value:web3.utils.toHex(web3.utils.toWei(amount, 'ether')),
      nonce: web3.utils.toHex(nonce),
      gasPrice: web3.utils.toWei('20', 'gwei'),
      gasLimit: web3.utils.toHex(21000),
      chainId: 11155111 // Chain ID for Sepolia testnet
    };

    const signedTx = await web3.eth.accounts.signTransaction(tx, privateKey);

    web3.eth.sendSignedTransaction(signedTx.rawTransaction)
      .on('receipt', (receipt) => {
        if (onSuccess) onSuccess(receipt); // Call the onSuccess callback with the receipt
      })
      .on('error', async (error) => {
        console.log(error);
      });

  } catch (e) {
    console.log(e);

  }
};



export const getTrxToUsdRate = async () => {
  const web3_polygon_instance = new Web3(trx_usd.baseUrl);
  const priceFeed = new web3_polygon_instance.eth.Contract(trx_usd.abi, trx_usd.address);

  const latestRoundData = await priceFeed.methods.latestRoundData().call();
  const trxToUsdRate = latestRoundData.answer / 10 ** 8; // Chainlink prices are returned with 8 decimals
  return trxToUsdRate;
};


// export const trxToUSD = (trxValue) => {
//   const rate = getTrxToUsdRate();
//   return rate * trxValue;
// };

export const getEthToUsdRate = async () => {
  const priceFeed = new web3.eth.Contract(eth_usd.abi, eth_usd.address);
  const latestRoundData = await priceFeed.methods.latestRoundData().call();
  const ethToUsdRate = latestRoundData.answer / 10 ** 8; // Chainlink prices are returned with 8 decimals
  return ethToUsdRate;

};

//wei to usd converter  
export const weiToUSDT = (weiValue, rate) => {
  const ethValue = parseFloat(weiValue) / Math.pow(10, 18);
  const usdValue = ethValue * rate;

  // Format the value to 2 decimal places with currency symbol
  const formattedValue = usdValue.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return formattedValue; // "$1,501.16"
}

export const USD = (value, min_fraction_digits = 2, max_fraction_digits = 2) => Number(value).toLocaleString('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: min_fraction_digits,
  maximumFractionDigits: max_fraction_digits
})
export const commarize = (value, min_fraction_digits = 2, max_fraction_digits = 2) => Number(value).toLocaleString('en-US', {
  minimumFractionDigits: min_fraction_digits,
  maximumFractionDigits: max_fraction_digits
})



export const isEthAddress = (address) =>web3.utils.isAddress(address);


