import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import mail_red from '../images/mail-secure-red.svg';
import mail_blue from '../images/mail-secure-blue.svg';
import eye from '../images/eye.svg';
import { globals } from '../utils/themes';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',maxWidth: 500, width: '100%',
    },
    label: {
        color:theme.palette.text.primary
    },
    inputWrapper: {
        position: 'relative',
    },
    input: {
        border: '1px solid transparent',
        padding: '22px 55px 22px 16px',
        fontSize: '1rem',
        color: theme.palette.text.primary,
        borderRadius:globals.borderRadius,
        width:'100%',
        backgroundColor: '#FFFFFF00',
        border: `1px solid ${theme.palette.primary.stroke}`,
        '&::placeholder': {
            color: theme.palette.text.secondary, fontSize:13,
        },
        '&.error': {
            borderColor: 'red',
        },
        '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
            outline: 'none',
        },
        '&:focus + div path': {
            fill: theme.palette.primary.main,
        },
        '&.error + div path': {
            fill: theme.palette.primary.red,
        },
    },
    iconCont: {
        position: 'absolute',
        top: '50%',
        right: '1.3rem',
        cursor: 'pointer',
        transform: 'translateY(-50%)',
        padding: '5px',
        display:'flex',justifyContent: 'center',alignItems:'center',
        '& path':{
            fill: theme.palette.text.primary,
        }
    },
    errorMessage: {
        color: theme.palette.primary.red,
        padding: 0,
        marginBottom: 10,
    },
}));

const Input = ({ id, className, errorMessage = '', placeholder = 'Enter value', type = 'text', label = 'Label', showIcon=true, icon=null, ...rest }) => {
    const classes = useStyles();
    const inp = useRef();
    const [inputType, setInputType] = useState(type);

    const eyes = (
        <svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.6394 4.92721C17.7538 3.62471 15.0525 2.25 12 2.25C8.94756 2.25 6.24625 3.62471 4.3606 4.92721C3.40826 5.58503 2.63887 6.24179 2.10629 6.73514C1.84643 6.97586 1.42164 7.41189 1.26681 7.57083L1.25502 7.58292C0.886606 7.99436 0.921484 8.62657 1.33293 8.99498C1.74409 9.36315 2.37571 9.32857 2.74424 8.91792L2.74502 8.91706C2.86755 8.7913 3.22618 8.42398 3.46543 8.20236C3.94491 7.75821 4.63998 7.16497 5.49728 6.57279C7.23092 5.37529 9.52961 4.25 12 4.25C14.4704 4.25 16.7691 5.37529 18.5027 6.57279C19.36 7.16497 20.0551 7.75821 20.5346 8.20236C20.7738 8.42398 21.1332 8.79216 21.2558 8.91792C21.6243 9.32857 22.2559 9.36315 22.6671 8.99498C23.0785 8.62657 23.1134 7.99436 22.745 7.58292L22.7331 7.57071C22.5782 7.41167 22.1535 6.9758 21.8937 6.73514C21.3612 6.24179 20.5918 5.58503 19.6394 4.92721Z" fill="#141B34"/>
<path opacity="0.4" d="M5.52031 8.71298C7.22595 7.38141 9.42944 6.25 12 6.25C14.5706 6.25 16.774 7.38141 18.4797 8.71298C20.1869 10.0458 21.502 11.694 22.2078 12.6837C22.461 13.0358 22.75 13.4378 22.75 14C22.75 14.5622 22.461 14.9642 22.2078 15.3163C21.502 16.306 20.1869 17.9542 18.4797 19.287C16.774 20.6186 14.5706 21.75 12 21.75C9.42944 21.75 7.22595 20.6186 5.52031 19.287C3.81313 17.9542 2.49805 16.306 1.79219 15.3163C1.53904 14.9642 1.25 14.5622 1.25 14C1.25 13.4378 1.53904 13.0358 1.79219 12.6837C2.49804 11.694 3.81313 10.0458 5.52031 8.71298Z" fill="#141B34"/>
<path fillRule="evenodd" clipRule="evenodd" d="M12 16C10.8954 16 10 15.1046 10 14C10 12.8954 10.8954 12 12 12C13.1046 12 14 12.8954 14 14C14 15.1046 13.1046 16 12 16ZM8 14C8 16.2091 9.79086 18 12 18C14.2091 18 16 16.2091 16 14C16 11.7909 14.2091 10 12 10C9.79086 10 8 11.7909 8 14Z" fill="#141B34"/>
</svg>);

const mail = (<svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M9.07999 1.7868C11.033 1.73773 12.967 1.73773 14.92 1.78681L14.9782 1.78827C16.503 1.82656 17.73 1.85737 18.7133 2.02862C19.7428 2.20791 20.5795 2.55179 21.2864 3.26134C21.9903 3.96792 22.3324 4.79261 22.5082 5.80542C22.6757 6.76979 22.7012 7.96683 22.7328 9.44967L22.7341 9.5084C22.7488 10.1997 22.7498 11.0515 22.742 11.8411C22.7374 12.3004 22.7351 12.5301 22.6003 12.6177C22.4654 12.7054 22.2512 12.6129 21.8226 12.4278C21.2729 12.1904 20.6731 11.9365 20.6331 11.9217C20.0263 11.6232 19.138 11.25 17.9993 11.25C16.8605 11.25 15.9724 11.6232 15.3656 11.9218L14.8626 12.1793L14.8583 12.1816C14.5253 12.3562 14.4095 12.4118 14.288 12.4487L14.2639 12.456C14.1028 12.5047 13.8772 12.5731 13.7014 12.6385L13.69 12.6427C13.5459 12.6961 13.0506 12.8796 12.6348 13.3186C12.2649 13.7092 12.1039 14.1255 12.026 14.3847C11.9691 14.5743 11.9283 14.7914 11.9106 14.885L11.9076 14.9013C11.6196 16.4193 11.732 17.9579 12.2575 19.3291C12.4266 19.7705 12.5112 19.9912 12.4214 20.1213C12.3317 20.2514 12.1117 20.2508 11.6717 20.2495C10.8093 20.2471 9.94653 20.235 9.07999 20.2132L9.02177 20.2117C7.49697 20.1735 6.27001 20.1426 5.2867 19.9714C4.2572 19.7921 3.42048 19.4482 2.71362 18.7387C2.00972 18.0321 1.66764 17.2074 1.49176 16.1946C1.32429 15.2302 1.29879 14.0332 1.26719 12.5504L1.26594 12.4916C1.24469 11.4953 1.24469 10.5047 1.26593 9.50839L1.26719 9.44965C1.29878 7.9668 1.32429 6.76978 1.49176 5.8054C1.66764 4.7926 2.00971 3.9679 2.71362 3.26132C3.42048 2.55177 4.2572 2.20789 5.2867 2.0286C6.27002 1.85735 7.497 1.82655 9.02182 1.78826L9.07999 1.7868Z" fill="#141B34"/>
    <path d="M6.13935 6.99122C6.42043 6.51581 7.03369 6.35828 7.5091 6.63935L10.4511 8.37878C11.2914 8.87562 11.6898 9.00016 12.0002 9.00016C12.3105 9.00016 12.7089 8.87562 13.5492 8.37878L16.4912 6.63935C16.9666 6.35828 17.5799 6.51581 17.861 6.99122C18.142 7.46663 17.9845 8.07988 17.5091 8.36096L14.5671 10.1004C13.6916 10.618 12.8898 11.0002 12.0002 11.0002C11.1105 11.0002 10.3088 10.618 9.43323 10.1004L6.49122 8.36096C6.01581 8.07988 5.85828 7.46663 6.13935 6.99122Z" fill="#141B34"/>
    <path d="M16.027 13.2677C16.5525 13.0091 17.1965 12.75 17.9983 12.75C18.8001 12.75 19.4444 13.0091 19.97 13.2676C20.1484 13.3554 20.3028 13.4363 20.4432 13.5099C20.7534 13.6725 20.995 13.7991 21.2759 13.8842C21.4557 13.9387 21.6433 13.9955 21.7742 14.0442C21.8865 14.086 22.105 14.1705 22.2751 14.35C22.4251 14.5083 22.4959 14.6817 22.5364 14.8162C22.5697 14.9272 22.5978 15.0758 22.6176 15.1802C23.1751 18.1167 21.9472 20.956 18.9345 22.0518C18.6613 22.1513 18.3902 22.25 17.9997 22.25C17.6092 22.25 17.3381 22.1513 17.065 22.0518C14.0523 20.9561 12.823 18.117 13.3804 15.1802C13.4002 15.0758 13.4283 14.9273 13.4616 14.8163C13.5021 14.6818 13.5729 14.5084 13.7229 14.3501C13.8929 14.1706 14.1115 14.0861 14.2237 14.0443C14.3546 13.9956 14.5422 13.9387 14.722 13.8842C15.0027 13.7991 15.2441 13.6726 15.554 13.51C15.6943 13.4364 15.8486 13.3555 16.027 13.2677Z" fill="#141B34"/>
    </svg>
    );


    const toggleVisibility = useCallback(() => {
        if (type === 'password') {
            setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'));
        }
    }, [type]);

    useEffect(() => {
        setInputType(type);
    }, [type]);

    return (
        <div className={classes.root}>
            <label htmlFor={id} className={classes.label}>{label}</label>
            <div className={classes.inputWrapper}>
                <input
                    id={id}
                    ref={inp}
                    placeholder={placeholder}
                    className={`${classes.input} ${className || ''} ${errorMessage.length > 0 ? 'error' : ''}`}
                    type={inputType}
                    {...rest}
                />
                    {showIcon && (<div className={classes.iconCont} role='button' onClick={toggleVisibility}>
                        {type === 'password' ? eyes: type==='email'? mail : icon}                        
                    </div>)}
            </div>

           { <p className={classes.errorMessage}>{errorMessage}</p>}
        </div>
    );
};

export default memo(Input);
