import { useTheme } from "@emotion/react";
import tx from "../images/history.svg";

export default function NoTx(){
    const theme = useTheme();

    return( <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',width:'100%',gap:20}}>
        <img src={tx} alt="" style={{opacity:0.05,filter:`invert(${theme.palette.mode ==='dark'?'0':'1'})`}} width={100}/>
        <p style={{color:theme.palette.text.secondary}}>You have no transactions</p>
    </div>);
}