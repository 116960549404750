import React, { memo, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Head from '../components/header';
import { CreateUserByEmail, PinResending, VerifyEmail } from '../utils/endpoints';
import PinBox from '../components/pinBox';
import { getItem, handleError, useCountdown } from '../utils';
import { useNavigate } from'react-router-dom';
import Info from '../components/info';
import Spinner from '../components/spinner';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex', marginTop: '60px',
        flexDirection: 'column',
        alignItems: 'center',
    },
    titleSection: {
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        '& h3': {
            color: theme.palette.text.primary,
        },
        '& p': {
            color: theme.palette.text.secondary,
            padding: theme.spacing(1, 2),
            fontSize: '14px',
        },
    },
    loginSection: {
        marginTop: theme.spacing(10),color: theme.palette.text.primary,
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontSize: '14px',
            fontWeight: 'bold',
        },
    },
}));
function EmailVerification(){
    const classes = useStyles();
    const navigate = useNavigate();
    const [showLoader, setShowLoading] = useState(false);


    const [info, setInfo] = useState({type:'error', message:'',status:false});
    const [pinValue, setPinValue] = useState('');

    useEffect(() => {

        const uid = getItem('__FLASHUSDT__');
        if(!uid) navigate('/signup');

        if(pinValue.length ===4) {
            proceedSubmission(uid);
        }
        
    },[pinValue]);

    const proceedSubmission = useCallback( async (uid) => {
        setShowLoading(true);
        VerifyEmail({Pin: pinValue},uid).then((res) => {
            navigate('/signup/profile');
            
        }).catch((e) => {
            setInfo({...info,status:true,message:handleError(e)});
        }).finally(() => {
            setShowLoading(false);
        });
     
    },[pinValue]);
    const ResendPin = useCallback( async () => {
        const uid = getItem('__FLASHUSDT__');
        PinResending(uid).then((res) => {
            setStatus(false);
            setInfo({...info,status:true,message:'Pin resent successfully',type:'success'});
        }).catch((e) => {
            setInfo({...info,status:true,message:handleError(e)});
        }).finally(() => {
            setShowLoading(false);
        });
     
    },[pinValue]);

    const {setStartTimestamp, status, timeLeft, setStatus} = useCountdown();
    // const []= use
    useEffect(()=>{
        if(!status){
            setStartTimestamp(new Date().getTime() + 1 * 60 * 1000); // 1 minute
        }
        
    },[status])

    return (
        <>
            <Head backTo='/signup' currentPageTitle='Email Verification' />

            <div className={classes.root}>
            
                <div className={classes.titleSection}>
                    <h3>Enter Verification Pin</h3>
                    <p>Enter the 4-digit code sent to you</p>
                </div>

            <Info type={info.type} autoRemove={true} display={info.status} setDisplay={(st)=>setInfo({...info,status:st})}>{info.message}</Info>

                <div>
                    <PinBox getValue={setPinValue}/>
                </div>

                <div className={classes.loginSection}>
                    <p>Didn't get code? <a>
                    
                     {
                        status?
                        <span tabIndex={-1} role='button' onClick={ResendPin}> &nbsp; Resend</span>:

                        <span>Resend in ({timeLeft.secs} secs)</span>
                    }
                     
                     </a>

                     </p>
                </div>
            </div>

            {showLoader && <Spinner/>}
        </>
    );

}
export default memo(EmailVerification);