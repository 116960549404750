import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Head from '../../components/header';
import { getItem, handleError, saveItem } from '../../utils';
import activeBtn from '../../images/active-btn.svg'
import Usercard from '../../components/usercard';
import Info from '../../components/info';
import Button from '../../components/button';
import Input from '../../components/input';
import Flyout from '../../components/flyout';
import { VerifyLicenseKey } from '../../utils/endpoints';
import Nav from '../../components/nav';




const useStyles = makeStyles((theme) => ({
    root:{
        overflowY: 'scroll',
        display:'flex', alignItems: 'center', flexDirection: 'column',
        justifyContent:'center',gap:20, marginTop:'2rem'
    },
    info__:{
        fontSize: '12px'
    }
 
       

}));


  

export default memo(function AccountActivation({setUserTheme=()=>null}) {
    const [Page, setPage] = useState(2);
    const [message, setMessage] = useState('');
    const [inputMessage, setInputMessage] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [btnLoading, setBtnLoading] = useState('');
    const classes= useStyles();
    const [showFlyout, setShowFlyout] = useState(false);
    const nav =useNavigate();

    useEffect(()=>{
        const userData  = getItem('__FLASHUSDT_USER__');
        if(userData){
            setMessage(userData.AccountType === 'FREE' ? "Your account is inactive. Upgrade account to demo or premium to access more features.":
                userData.AccountType === 'DEMO' ? "You're currently running a demo account, you can upgrade anytime to a premium to access more features":"You are on a Premium account");
        }
        if(Page===1){
            setMessage("Your account is inactive. Input activation code purchased to upgrade and access more features. ")
        }
        
    },[Page])

    const processForm=useCallback(()=>{
        if(inputValue.trim().length < 10){
            setInputMessage("Invalid license code");
        }else{
            setInputMessage("");
            submitCode();
        }        
    },[inputValue])

    const submitCode = async()=>{
        const uid = getItem('__FLASHUSDT__');
        setBtnLoading(true);
        let keytype = (inputValue.split('-').length > 1) ? 'premiumcode' : 'democode';
        VerifyLicenseKey(uid,inputValue,keytype).then((res)=>{
            saveItem('__FLASHUSDT_USER__',res.data.data);
            setShowFlyout(true);
        }).catch((e)=>{
            setInputMessage(handleError(e));
        }).finally(()=>{
            setBtnLoading(false); 
        });
    }

    return(
        <div style={{paddingTop:36}}>
            <Head backTo='/dashboard/settings' currentPageTitle='Account Status'/>

            <Info className={classes['info__']} display={true}>
               {message}
            </Info>

                {
                    Page === 2 && (
                        <div className={`${classes.root}`}>
                            <Usercard withCont={true}
                                icon={(<div tabIndex={-1} onClick={() => setPage(1)} role='button' className='icon-cont'><img src={activeBtn} alt="" /></div>)} />
                                <Button text='Get license key' onClick={()=>nav('/dashboard/support')} />
                        </div>
                    )
                    
                }
                {
                    Page === 1 && (
                        <div className={`${classes.root}`}>
                            <Input  id={'4ed3c32'} label='License key' value={inputValue} onChange={(e)=>setInputValue(e.target.value)} placeholder='Enter the license key optained here' showIcon={false} errorMessage={inputMessage}/>
                            <Button text='Activate account'  onClick={processForm} loading={btnLoading} />
                        </div>
                    )
                }


            {
                showFlyout && (
                    <Flyout title="Account Activated" descr={<span>Your account has been successfully activated.  <br/> Proceed to dashboard.</span>}>
                        <Button text='Goto dashboard' onClick={()=>nav('/dashboard')} />
                    </Flyout>
                )
            }

<Nav/>
        </div>
    );


})